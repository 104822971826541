import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from '@restart/ui';
import { CheckBoxWithIndeterminate } from '../../../components/input/InputType';
import AddInvitationModal from '../../../components/modalbootstrap/AddInvitationModal';
import ResendInvitationModal from '../../../components/modalbootstrap/ResendInvitationModal';
import ConfirmModal from '../../../components/modalbootstrap/ConfirmModal';
import SuccessModal from '../../../components/modalbootstrap/SuccessModal';
import Skeleton from 'react-loading-skeleton';
import OffsetPaginator from '../../../components/paginate/OffsetPaginator';
import { svgIcons } from '../../../components/svgIcons/svgIcons';
import { apiAgent } from '../../../utils/apicall';
import { fetchSearchPhrase } from '../../../features/searchphrase/searchPhraseSlice';
import { setSearchPhrase } from '../../../features/searchphrase/searchPhraseSlice';
import RightPanelModal from '../../../components/modalbootstrap/RightPanelModal';
import UpdateEmailModal from '../../../components/modalbootstrap/UpdateEmailModal';
import DeleteModal from '../../../components/modalbootstrap/DeleteModal';
import LinesEllipsis from 'react-lines-ellipsis';
import Paginater from '../../../components/paginate/Paginater';
import { ProjectStatus } from '../../../utils/constants';
import { CheckBox } from '../../../components/input/InputType';

import { Button, Input, Tabs, Tab } from '@nextui-org/react';
import { intersection, result } from 'lodash-es';
import uxDialog from '../../../utils/uxdialog';

const orderParams = ['name', '', '', '', '', ''];

export default function Invitation() {
  const SORT_DICT = {
    field0: 'name',
    field1: 'email',
    field2: 'accepted',
    field3: 'inviter',
    field4: 'company',
    field5: 'sent',
    field6: 'permission_role',
  };
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const searchPhrase = useSelector(fetchSearchPhrase);
  const [infoLoaded, setInfoLoaded] = useState(false);
  const dispatch = useDispatch();
  // #region states
  // data
  const [companies, setCompanies] = useState([]);
  // users list
  useState('Bookmark: Users List');
  const [activeUsersTab, setActiveUsersTab] = useState('PMC users'); // active users list tab
  const [checkedNum, setCheckedNum] = useState(0); // count of checked invitations
  const [usersSelectStat, setUsersSelectStat] = useState(false); // false (none), 'intermediate' (some), true (all)
  const [users, setUsers] = useState([]); // invite objects
  const [usersTotalCount, setUsersTotalCount] = useState(0); // total count
  const [usersLoadOffset, setUsersLoadOffset] = useState(0); // pagination offset
  const [usersLoadPageSize, setUsersLoadPageSize] = useState(10); // pagination size
  const [selectCompany, setSelectCompany] = useState(); // not used
  const usersCurrentRequestRef = useRef(null);
  // action modals (update invitation email, delete invitation, resend invite)
  useState('Bookmark: Action Modals');
  const [openActionModal, setOpenActionModal] = useState(''); // 'update' 'delete' ''
  const [actionModalInviteId, setActionModalInviteId] = useState(); // invitation id
  const [actionModalUsername, setActionModalUsername] = useState('');
  const [showResendInvitationModal, setShowResendInvitationModal] =
    useState(false);
  // add invitation main modal RightPanelModal
  useState('Bookmark: add invitation main modal RightPanelModal');
  const [showAddInvitationModal, setShowAddInvitationModal] = useState(false);
  const [inviteModalActiveTab, setInviteModalActiveTab] = useState('PMC users'); // active tab
  const [loadingPropertiesFlag, setLoadingPropertiesFlag] = useState(false);
  const [properties, setProperties] = useState([]); // properties list
  const [propertiesSearchPhrase, setPropertiesSearchPhrase] = useState('');
  const [propertiesTotalCount, setPropertiesTotalCount] = useState(0); // total properties
  const [propertiesOrdering, setPropertiesOrdering] = useState('-created_at'); // properties fetch option
  const [propertiesFiltering, setPropertiesFiltering] = useState({
    project__ads_status__in: Object.values(ProjectStatus).join(','),
  }); // properties fetch option
  const [propertiesPageIndex, setPropertiesPageIndex] = useState(0); // properties
  const [propertiesPageLimit, setPropertiesPageLimit] = useState(10); // properties
  const [propertiesSelected, setPropertiesSelected] = useState([]); // ids of selected properties
  const propertiesCurrentRequestRef = useRef(null);
  const selectProperty = (id, select) => {
    setPropertiesSelected((prev) => {
      if (!select) {
        return prev.filter((value) => {
          return value != id;
        });
      } else {
        const newIds = [].concat(prev);
        if (newIds.indexOf(id) < 0) {
          newIds.push(id);
        }
        return newIds;
      }
    });
  };
  const [propertiesSelectStat, setPropertiesSelectStat] = useState(false);
  const [email, setEmail] = useState(''); // invite email
  // add invitation submodal AddInvitationModal
  useState('Bookmark: add invitation submodal AddInvitationModal');
  const [showAddInvitaionSubModal, setShowAddInvitaionSubModal] =
    useState(false);
  // other / confirm modal
  useState('Bookmark: other / confirm modal');
  const [openHelperModal, setOpenHelperModal] = useState(null); // null 'PermissionRole' 'SuccessDelete' 'SuccessInvite'
  // #endregion

  // #region utils

  // base data
  const fetchCompanyList = async () => {
    const response = await apiAgent.get({
      path: 'companies/',
      options: apiAgent.popularOptions.onConfirmExceptDeclined,
    });
    const result = await response.json();
    setCompanies(result);
  };

  // users list
  const useSortableData = (config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);
    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };

    return { requestSort, sortConfig };
  };
  const { requestSort, sortConfig } = useSortableData();

  const updateInviteList = async (
    selectCompany,
    searchPhrase,
    offset,
    size,
    sortConfig,
    usersCategory,
  ) => {
    const requestId = Date.now();
    usersCurrentRequestRef.current = requestId;

    var ordering = '';
    if (sortConfig) {
      if (sortConfig.direction === 'descending') {
        ordering = '-' + sortConfig.key;
      } else {
        ordering = sortConfig.key;
      }
    }
    let params;
    params = {
      offset,
      limit: size,
      ordering,
      search: searchPhrase,
    };

    if (selectCompany) {
      params.company_id = selectCompany.value;
    }

    if (usersCategory == 'PMC users') {
      params.permission_role = 'manager';
    } else {
      params.permission_role__in = 'admin,agency';
    }
    try {
      const respones = await apiAgent.get({
        path: 'invitations/',
        params: params,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const resp = await respones.json();
      if (usersCurrentRequestRef.current !== requestId) {
        return;
      }
      const result = resp.results;
      setUsersTotalCount(resp.count);
      setUsers(result.map((item) => ({ ...item, checked: false })));
      console.log(result.map((item) => ({ ...item, checked: false })));
      setInfoLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  // add invite modal / properties
  const updatePropertiesList = async () => {
    setLoadingPropertiesFlag(true);
    const requestId = Date.now();
    propertiesCurrentRequestRef.current = requestId;
    try {
      let params = {
        ordering: propertiesOrdering,
        search: propertiesSearchPhrase,
        offset: propertiesPageIndex * propertiesPageLimit,
        limit: propertiesPageLimit,
      };
      params = Object.assign(params, propertiesFiltering);
      const response = await apiAgent.get({
        path: `/properties/`,
        params,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const responseResult = await response.json();
      if (propertiesCurrentRequestRef.current !== requestId) {
        return;
      }
      setPropertiesTotalCount(responseResult.count);
      setProperties(responseResult.results);
      setLoadingPropertiesFlag(false);
    } catch (error) {
      console.log(error);
      if (propertiesCurrentRequestRef.current !== requestId) {
        return;
      }
      setLoadingPropertiesFlag(false);
    }
  };

  // other
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? 'w-2' : '';
  };
  const getArrow = (name) => {
    if (!sortConfig) {
      return;
    } else if (sortConfig.direction === 'ascending') {
      return svgIcons.dropdown;
    } else if (sortConfig.direction === 'descending') {
      return svgIcons.dropup;
    }
  };
  const convertDateFormat = (date) => {
    const d = new Date(date);
    let text = d.toString();
    let arr = text.split(' ');
    let showDate = arr[1] + ' ' + arr[2] + ', ' + arr[3];
    return showDate;
  };

  const checkIfExpired = (sent) => {
    const sentDate = new Date(sent);
    const resultDate = new Date(sentDate.getTime() + 3 * 24 * 60 * 60 * 1000);
    const today = new Date();
    return resultDate >= today;
  };
  // #endregion

  // #region useEffect hooks

  // data

  useEffect(() => {
    window.document.title = 'Settings | Flair';
    setInfoLoaded(false);
    fetchCompanyList();
  }, []);

  // users list

  useEffect(() => {
    updateInviteList(
      selectCompany,
      searchPhrase,
      usersLoadOffset,
      usersLoadPageSize,
      sortConfig,
      activeUsersTab,
    );
  }, [
    selectCompany,
    searchPhrase,
    usersLoadOffset,
    usersLoadPageSize,
    sortConfig,
    activeUsersTab,
  ]);

  useEffect(() => {
    let AND = true,
      OR = false;
    users.forEach((item) => {
      AND = AND && item.checked;
      OR = OR || item.checked;
    });
    if (!OR) setUsersSelectStat(false);
    else if (!AND) setUsersSelectStat('indeterminate');
    else setUsersSelectStat(true);
  }, [users]);

  useEffect(() => {
    var checkedNum = 0;
    for (var i = 0; i < users.length; i++) {
      if (users[i].checked) {
        checkedNum++;
      }
    }
    setCheckedNum(checkedNum);
  }, [users]);

  // add invitation main modal RightPanelModal

  useEffect(() => {
    // reset
    setPropertiesSelected([]);
  }, [inviteModalActiveTab, showAddInvitationModal]);

  useEffect(() => {
    const fetchedPropertyIds = properties.map((prop) => prop.id);
    // selected & properties == properties && selected.length -> true
    if (
      intersection(propertiesSelected, fetchedPropertyIds).length ==
        fetchedPropertyIds.length &&
      fetchedPropertyIds.length > 0
    ) {
      setPropertiesSelectStat(true);
    }
    // selected & properties == empty -> false
    else if (intersection(propertiesSelected, fetchedPropertyIds).length == 0) {
      setPropertiesSelectStat(false);
    }
    // other: intermediate
    else {
      setPropertiesSelectStat('indeterminate');
    }
  }, [propertiesSelected, properties]);

  useEffect(() => {
    updatePropertiesList();
  }, [
    propertiesPageIndex,
    propertiesPageLimit,
    propertiesOrdering,
    propertiesFiltering,
    propertiesSearchPhrase,
  ]);

  // #endregion
  // #region event handlers
  // users list
  const handlerMultiDelete = async () => {
    let id_group = [];
    if (userInfo.permission_role === 'reader') {
      setOpenHelperModal('PermissionRole');
    } else {
      for (var i = 0; i < users.length; i++) {
        if (users[i].checked) {
          id_group.push(users[i].id);
        }
      }
      try {
        const respones = await apiAgent.del({
          path: `invitations/batch_delete`,
          data: {
            id_group,
          },
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        setOpenHelperModal('SuccessDelete');
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSelectPage = ({ offset: _offset, size: _size }) => {
    setUsersLoadOffset(_offset);
    setUsersLoadPageSize(_size);
  };

  // action modals / update invite, delete invite, resend invite
  const handlerUpdate = async (value) => {
    if (userInfo.permission_role === 'reader') {
      setOpenHelperModal('PermissionRole');
    } else {
      try {
        setInfoLoaded(false);
        const response = await apiAgent.patch({
          path: `/invitations/${actionModalInviteId}`,
          data: { email: value },
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        updateInviteList(
          selectCompany,
          searchPhrase,
          usersLoadOffset,
          usersLoadPageSize,
          sortConfig,
          activeUsersTab,
        );
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handlerDelete = async () => {
    if (userInfo.permission_role === 'reader') {
      setOpenHelperModal('PermissionRole');
    } else {
      try {
        const response = await apiAgent.del({
          path: `/invitations/${actionModalInviteId}/`,
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        setOpenHelperModal('SuccessDelete');
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handlerSendInvite = (item) => {
    if (userInfo.permission_role === 'reader') {
      setOpenHelperModal('PermissionRole');
    } else {
      setShowResendInvitationModal(item);
    }
  };

  // add invitation main modal RightPanelModal
  const handleMultiInviteManager = async () => {
    if (email.length == 0) return;
    let id_group = propertiesSelected;

    if (userInfo.permission_role === 'reader') {
      setOpenHelperModal('PermissionRole');
    } else {
      try {
        const response = await apiAgent.post({
          path: `invitations/batch_add_invitation`,
          data: {
            id_group,
            email,
          },
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        const response_data = await response.json();
        if (response_data.error == 'The user already joined') {
          await uxDialog.alert({
            text: 'This user already has access to Flair.',
          });
        } else if (response_data.error == 'The user is already invited') {
          await uxDialog.alert({
            text: 'This user has already been invited to Flair. To resend the invitation, find the user invitation and click "resend invite".',
          });
        } else {
          setOpenHelperModal('SuccessInvite');
          setShowAddInvitationModal(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  // #endregion

  // #region rendering
  return infoLoaded ? (
    <div className="bg-white mt-6 rounded-xl shadow-md">
      <div className="grid grid-cols-1 lg:flex gap-4 px-4 py-6 justify-between">
        <div className="flex items-center">
          <Tabs
            key="none"
            radius="sm"
            selectedKey={activeUsersTab}
            onSelectionChange={setActiveUsersTab}
            className="border h-[44px] rounded-xl text-base leading-6 font-medium"
            classNames={{
              tabList: 'p-[1px] gap-0',
            }}
          >
            <Tab
              key="Agency users"
              title="Agency users"
              className="w-[130px] h-10"
            />
            <Tab key="PMC users" title="PMC users" className="w-[130px] h-10" />
          </Tabs>
        </div>
        <div className="grid grid-cols-1 md:flex items-center gap-4">
          <div className="relative lg:grow w-[410px]">
            <Input
              classNames={{
                base: 'w-full h-[52px]',
                mainWrapper: 'h-full',
                input: 'text-small pl-9',
                inputWrapper: 'h-full font-normal text-default-500 bg-white',
              }}
              variant="bordered"
              value={searchPhrase}
              onChange={(e) => dispatch(setSearchPhrase(e.target.value))}
              placeholder="Search"
              startContent={<div className="w-4">{svgIcons.search}</div>}
              type="search"
            />
          </div>
          {['admin', 'agency'].includes(userInfo.permission_role) && (
            <div className="grid grid-cols-1 md:items-center">
              <Button
                variant="solid"
                radius="sm"
                onClick={() => {
                  setEmail('');
                  // setPropertiesSelected([]); // handled by useEffect
                  setShowAddInvitationModal(true);
                }}
                className="w-[176px] h-[52px] bg-default-900 text-white text-base leading-6 font-medium"
                startContent={
                  <img src="/assets/images/add-square.png" width={24} alt="" />
                }
              >
                Invite user
              </Button>
            </div>
          )}
        </div>
      </div>
      {usersSelectStat && (
        <div className="w-full py-3 px-10 mb-4 leading-[15.6px] flex gap-6 items-center h-[64px] border-y">
          <div className="font-medium text-[14px] leading-[18.2px]">
            {checkedNum} items selected
          </div>
          <Button
            color="danger"
            variant="bordered"
            size="sm"
            onClick={() => handlerMultiDelete()}
          >
            Remove
          </Button>
        </div>
      )}
      <div className="w-full overflow-x-scroll relative pb-28 px-4">
        <table className="w-full font-medium text-xs leading-[15.6px]">
          <thead>
            <tr className="bg-[#F2F5F966]">
              {['admin', 'agency'].includes(userInfo.permission_role) && (
                <th className="pl-5 py-3 w-[18px]">
                  {!usersSelectStat ? (
                    <label className="check-container w-[18px] h-[18px] pl-0 mb-0 align-middle">
                      <input
                        type="checkbox"
                        checked={usersSelectStat}
                        onChange={(e) =>
                          setUsers((prev) =>
                            prev.map((item) => ({ ...item, checked: true })),
                          )
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  ) : (
                    <CheckBoxWithIndeterminate
                      width="18px"
                      value={usersSelectStat}
                      onClick={() =>
                        setUsers((prev) =>
                          prev.map((item) => ({ ...item, checked: false })),
                        )
                      }
                    />
                  )}
                </th>
              )}
              {['user', 'email address', 'status', 'last active', ''].map(
                (item, index) => (
                  <th className="pl-5 xs:pl-8 py-3">
                    <button
                      key={'field' + index}
                      type="button"
                      onClick={() => requestSort(SORT_DICT['field' + index])}
                      className="text-xs font-medium text-left uppercase flex gap-2 justify-between items-center"
                    >
                      {item}
                      <div
                        className={getClassNamesFor(SORT_DICT['field' + index])}
                      >
                        {getArrow(SORT_DICT['field' + index])}
                      </div>
                    </button>
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody className="text-[14px]">
            {users
              .filter(
                (item) =>
                  (activeUsersTab == 'PMC users' &&
                    ['manager', 'pmc'].includes(item.permission_role)) ||
                  (activeUsersTab == 'Agency users' &&
                    ['agency', 'admin'].includes(item.permission_role)),
              )
              .map((item, index) => (
                <tr
                  key={item.id}
                  className="px-5 xs:px-8 py-5 odd:bg-white even:bg-[#F2F5F966]"
                >
                  {['admin', 'agency'].includes(userInfo.permission_role) && (
                    <td className="pl-5 py-5 w-[3%]">
                      <label className="check-container w-[18px] h-[18px] pl-0 mb-0 align-middle">
                        <input
                          type="checkbox"
                          checked={item.checked}
                          onChange={(e) =>
                            setUsers((prev) => {
                              let arr = [...prev];
                              for (var i = 0; i < arr.length; i++) {
                                if (arr[i].id === item.id) {
                                  arr[i].checked = e.target.checked;
                                }
                              }
                              return arr;
                            })
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                    </td>
                  )}
                  <td className="px-5 xs:px-8 py-5 leading-3 group flex items-center gap-2">
                    {item.name && (
                      <div className="flex w-7 h-7 rounded-full bg-[#C0FFE6] items-center">
                        <p className="w-full text-center text-[#13B184]">
                          {item.name[0].toUpperCase()}
                        </p>
                      </div>
                    )}
                    {item.name ? (
                      <p className="text-[14px]">{item.name}</p>
                    ) : (
                      <p className="text-[14px] text-gray-400">No name</p>
                    )}
                  </td>
                  <td className="px-5 xs:px-8 py-5 leading-3 w-[22%]">
                    {item.email}
                  </td>
                  <td className="px-5 xs:px-8 py-5 leading-3 w-[20%]">
                    {item.accepted ? (
                      <div className="flex gap-2 items-center">
                        <span className="w-2 h-2 rounded-full bg-[#38D086]"></span>
                        <lable>Accepted</lable>
                      </div>
                    ) : checkIfExpired(item.sent) ? (
                      <div className="flex gap-2 items-center">
                        <span className="w-2 h-2 rounded-full bg-[#F6D214]"></span>
                        <lable>Invite sent</lable>
                      </div>
                    ) : (
                      <div className="flex gap-2 items-center">
                        <span className="w-2 h-2 rounded-full bg-[#EB4261]"></span>
                        <lable>Expired</lable>
                      </div>
                    )}
                  </td>
                  <td className="px-5 xs:px-8 py-5 leading-3 w-[20%]">
                    {convertDateFormat(item.sent)}
                  </td>
                  <td className="flex justify-end pr-8 py-5 min-w-[240px]">
                    {['admin', 'agency'].includes(userInfo.permission_role) && (
                      <div>
                        <Dropdown>
                          <Dropdown.Toggle>
                            {(props) => (
                              <button {...props} className="ml-24">
                                <img
                                  src="/assets/images/3-dots.png"
                                  width={24}
                                  alt=""
                                />
                              </button>
                            )}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {(menuProps, meta) => (
                              <ul
                                {...menuProps}
                                className="w-[224px] h-[128px] xs:w-auto absolute xs:bottom-auto xs:top-26 ml-[-100px] z-10 rounded-lg bg-white border border-[#E9ECF1] shadow-[0_20.3px_37.71px_rgba(183, 188, 200, 0.11)] whitespace-nowrap"
                                style={{
                                  boxShadow:
                                    '0px 20.3px 37.71px rgba(183, 188, 200, 0.11)',
                                  transition:
                                    'visibility 800ms, opacity 800ms, transform 800ms',
                                  visibility: meta.show ? 'visible' : 'hidden',
                                  opacity: meta.show ? '1' : '0',
                                  transform: meta.show
                                    ? 'translateY(6px)'
                                    : 'translateY(-4px)',
                                }}
                              >
                                <div className="py-1 grid w-[224px]">
                                  <button
                                    onClick={() => {
                                      meta.toggle();
                                      if (item.accepted) {
                                        alert(
                                          'Sorry. You cannot update the email as the inviation is already accepted.',
                                        );
                                        return;
                                      }
                                      setOpenActionModal('update');
                                      setActionModalInviteId(item.id);
                                      setActionModalUsername(item.name);
                                    }}
                                    className={
                                      'flex items-center text-medium indent-2 h-[41px] p-3' +
                                      (item.accepted
                                        ? ' default-400-text'
                                        : ' base-foreground-text')
                                    }
                                  >
                                    Update email
                                  </button>
                                  <button
                                    onClick={() => {
                                      meta.toggle();
                                      if (item.accepted) return;
                                      handlerSendInvite(item);
                                    }}
                                    className={
                                      'flex items-center text-medium indent-2 h-[41px] p-3' +
                                      (item.accepted
                                        ? ' default-400-text'
                                        : ' base-foreground-text')
                                    }
                                  >
                                    Resend invite
                                  </button>
                                  <button
                                    onClick={() => {
                                      if (item.accepted) return;
                                      setOpenActionModal('delete');
                                      setActionModalInviteId(item.id);
                                      setActionModalUsername(item.name);
                                    }}
                                    className={
                                      'text-medium flex items-center indent-2 h-[41px] p-3' +
                                      (item.accepted
                                        ? ' default-400-text'
                                        : ' text-[#EB4261]')
                                    }
                                  >
                                    Remove invite
                                  </button>
                                </div>
                              </ul>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="px-5 xs:px-8">
        <OffsetPaginator
          onSelectPage={handleSelectPage}
          total={usersTotalCount}
        />
      </div>

      <RightPanelModal
        show={showAddInvitationModal}
        onHide={() => setShowAddInvitationModal(false)}
        onClose={() => setShowAddInvitationModal(false)}
        title="Invite Manager"
        handleConfirm={() => {
          if (inviteModalActiveTab == 'PMC users') handleMultiInviteManager();
          else {
            setShowAddInvitationModal(false);
            setShowAddInvitaionSubModal(true);
          }
        }}
        content={
          <>
            <div className="font-saans-trial">
              <div className="mt-8 leading-7">
                <div className="flex p-[2px] rounded">
                  <Tabs
                    key="none"
                    radius="sm"
                    selectedKey={inviteModalActiveTab}
                    onSelectionChange={setInviteModalActiveTab}
                    className="border h-[44px] rounded-xl text-base leading-6 font-medium"
                    classNames={{
                      tabList: 'p-[1px] gap-0',
                    }}
                  >
                    <Tab
                      key="Agency users"
                      title="Agency users"
                      className="w-[204px] h-10"
                    />
                    <Tab
                      key="PMC users"
                      title="PMC users"
                      className="w-[204px] h-10"
                    />
                  </Tabs>
                </div>
                <div className="text-base leading-5 mt-5">
                  Enter email address of the person you’d like to invite below
                </div>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="bordered"
                  placeholder="Email"
                  classNames={{
                    base: 'w-full mt-4 mb-6',
                    inputWrapper: 'h-[52px] text-base leading-6 font-normal',
                  }}
                />
              </div>
              {inviteModalActiveTab == 'PMC users' && (
                <>
                  <div className="flex items-center justify-between border-t-1 pt-5">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        setPropertiesPageIndex(0);
                        updatePropertiesList();
                      }}
                      className="w-full relative"
                    >
                      <Input
                        classNames={{
                          base: 'max-w-full h-[52px]',
                          mainWrapper: 'h-full',
                          input: 'text-small pl-9',
                          inputWrapper:
                            'h-full font-normal text-default-500 bg-white',
                        }}
                        variant="bordered"
                        value={propertiesSearchPhrase}
                        onChange={(e) =>
                          dispatch(setPropertiesSearchPhrase(e.target.value))
                        }
                        placeholder="Search your properties here"
                        startContent={
                          <div className="w-4">{svgIcons.search}</div>
                        }
                        type="search"
                      />
                    </form>
                  </div>
                  <div className="shadow-[0_0_20.303px_37.7056px_rgba(183,188,200,0.11)] grid grid-cols-1 mt-4 mb-4 border rounded-lg">
                    <div className="w-full">
                      <table className="border-collapse whitespace-nowrap w-full xs:min-w-fit relative">
                        <thead>
                          <tr className="bg-[#F2F5F966] border-t border-b border-[#E9ECF1]">
                            <th className="w-10 px-6 leading-3 hidden xs:table-cell">
                              <CheckBox
                                state={propertiesSelectStat}
                                onChange={(e) => {
                                  properties.map((property) => {
                                    selectProperty(
                                      property.id,
                                      e.target.checked,
                                    );
                                  });
                                }}
                              />
                            </th>
                            {['property'].map((item, index) => (
                              <th
                                key={index}
                                className={`last:xs:pr-4 ${
                                  ![0, 2].includes(index) && 'hidden'
                                } xs:table-cell`}
                              >
                                <button
                                  onClick={() =>
                                    ['property', 'status', 'budget'].includes(
                                      item,
                                    ) &&
                                    setPropertiesOrdering((prev) =>
                                      prev.includes(orderParams[index])
                                        ? orderParams[index] === prev
                                          ? `-${orderParams[index]}`
                                          : orderParams[index]
                                        : orderParams[index],
                                    )
                                  }
                                  className="uppercase text-[#707787] text-xs font-medium block px-3 xs:px-4 py-3 w-full text-left group"
                                >
                                  {item}
                                  <div
                                    className={
                                      propertiesOrdering ===
                                      `-${orderParams[index]}`
                                        ? 'ml-2 inline-block w-1.5 align-middle pb-[3px] text-[#687BFE]'
                                        : 'ml-2 inline-block w-1.5 align-middle pb-[3px] text-[#CED3DB] invisible group-hover:visible'
                                    }
                                  >
                                    {svgIcons.up_vector}
                                  </div>
                                  <div
                                    className={
                                      propertiesOrdering ===
                                      `${orderParams[index]}`
                                        ? 'inline-block w-1.5 align-middle pb-[3px] text-[#687BFE]'
                                        : 'inline-block w-1.5 align-middle pb-[3px] text-[#CED3DB] invisible group-hover:visible'
                                    }
                                  >
                                    {svgIcons.down_vector}
                                  </div>
                                </button>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {properties.map((item, index) => (
                            <tr
                              className="even:bg-[#F2F5F966] hover:bg-[#687BFE14] border-t border-b border-[#E9ECF1]"
                              key={item.id}
                            >
                              <td className="w-10 xs:w-16 px-6 text-[14px] leading-3 hidden xs:table-cell">
                                <CheckBox
                                  state={
                                    propertiesSelected.indexOf(item.id) >= 0
                                  }
                                  onChange={(e) =>
                                    selectProperty(item.id, e.target.checked)
                                  }
                                />
                              </td>
                              <td className="w-[349px] border-l-4 xs:border-0 flex gap-2 p-2 items-center">
                                <img
                                  src={
                                    item.highlight_photo
                                      ? item.highlight_photo
                                      : './assets/images/tile-noimg.svg'
                                  }
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src =
                                      './assets/images/tile-noimg.svg';
                                  }}
                                  className="w-[30px] h-[30px] object-cover"
                                  alt="alter"
                                />
                                <LinesEllipsis
                                  text={item.name}
                                  component="span"
                                  maxLine="1"
                                  ellipsis="…"
                                  trimRight
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="flex flex-col justify-center items-center mt-2">
                      <p className="whitespace-nowrap text-[#707787]">
                        Displaying {properties.length} properties out of{' '}
                        {propertiesTotalCount} total
                      </p>
                      <Paginater
                        total={propertiesTotalCount}
                        pageIndex={propertiesPageIndex}
                        pageLimit={propertiesPageLimit}
                        onChangePageIndex={setPropertiesPageIndex}
                        onChangePageLimit={setPropertiesPageLimit}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        }
      />
      <UpdateEmailModal
        show={openActionModal === 'update'}
        onClose={() => setOpenActionModal('')}
        mark={{
          icon: <img src="/assets/images/accept_dismiss.png" />,
          parentClass: 'text-[#687BFE] bg-[#687BFE]/10',
          childClass: 'w-[23.54px] h-[23.54px]',
        }}
        handleClose={() => setOpenActionModal('')}
        username={actionModalUsername}
        handleConfirm={(value) => {
          handlerUpdate(value);
          setOpenActionModal('');
        }}
      />
      <DeleteModal
        show={openActionModal === 'delete'}
        onClose={() => setOpenActionModal('')}
        mark={{
          icon: <img src="/assets/images/accept_dismiss.png" />,
          parentClass: 'text-[#687BFE] bg-[#687BFE]/10',
          childClass: 'w-[23.54px] h-[23.54px]',
        }}
        handleClose={() => setOpenActionModal('')}
        username={actionModalUsername}
        handleConfirm={() => {
          handlerDelete();
          setOpenActionModal('');
        }}
      />
      <AddInvitationModal
        companies={companies}
        show={showAddInvitaionSubModal}
        email={email}
        onClose={() => {
          setShowAddInvitaionSubModal(false);
          updateInviteList(
            selectCompany,
            searchPhrase,
            usersLoadOffset,
            usersLoadPageSize,
            sortConfig,
            activeUsersTab,
          );
        }}
      />
      <ResendInvitationModal
        companies={companies}
        show={showResendInvitationModal}
        onClose={() => {
          updateInviteList(
            selectCompany,
            searchPhrase,
            usersLoadOffset,
            usersLoadPageSize,
            sortConfig,
            activeUsersTab,
          );
          setShowResendInvitationModal(false);
        }}
      />
      <ConfirmModal
        show={openHelperModal === 'PermissionRole'}
        onHide={() => setOpenHelperModal(null)}
        onClose={() => setOpenHelperModal(null)}
        mark={{
          icon: svgIcons.warning,
          parentClass: 'text-[#EE380D] bg-[#EE380D]/10',
          childClass: 'w-[28px] h-[28px]',
        }}
        title="Wanning"
        txt="This action isn't available to View-only users. Reach out to your admin to request new permissions."
        handleConfirm={() => {
          setOpenHelperModal(null);
        }}
      />
      <SuccessModal
        show={openHelperModal === 'SuccessDelete'}
        onHide={() => setOpenHelperModal(null)}
        onClose={() => setOpenHelperModal(null)}
        mark={{
          icon: svgIcons.success,
          parentClass: 'text-[#38D086] bg-[#38D086]/10',
          childClass: 'w-[30px] h-[17px]',
        }}
        title="Success"
        txt="The user was successfully deleted"
        modalConfirmBtn="Back to User Invitation"
        handleSuccess={() => {
          setOpenHelperModal(null);
          updateInviteList(
            selectCompany,
            searchPhrase,
            usersLoadOffset,
            usersLoadPageSize,
            sortConfig,
            activeUsersTab,
          );
        }}
      />
      <SuccessModal
        show={openHelperModal === 'SuccessInvite'}
        onHide={() => setOpenHelperModal(null)}
        onClose={() => setOpenHelperModal(null)}
        mark={{
          icon: svgIcons.success,
          parentClass: 'text-[#38D086] bg-[#38D086]/10',
          childClass: 'w-[30px] h-[17px]',
        }}
        title="Success"
        txt="Your invitation to join Flair has been sent"
        modalConfirmBtn="Back to User Invitation"
        handleSuccess={() => {
          setOpenHelperModal(null);
          updateInviteList(
            selectCompany,
            searchPhrase,
            usersLoadOffset,
            usersLoadPageSize,
            sortConfig,
            activeUsersTab,
          );
        }}
      />
    </div>
  ) : (
    <div className="bg-white mt-6 rounded-xl">
      <div className="grid grid-cols-1 lg:flex gap-4 px-4 py-6 justify-between">
        <div className="w-[264px]">
          <Skeleton
            height={42}
            borderRadius={12}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div>
        <div className="grid grid-cols-1 md:flex md:items-center gap-3">
          <div className="flex items-center gap-3 md:grow">
            <button className="indent-2 w-full lg:w-[410px]">
              <Skeleton
                height={42}
                borderRadius={12}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </button>
          </div>
          <button className="indent-2 md:w-40">
            <Skeleton
              height={42}
              borderRadius={8}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
          </button>
        </div>
      </div>
      <div className="w-full overflow-x-scroll relative pb-20">
        <table className="w-full font-medium text-xs leading-[15.6px]">
          <thead>
            <tr className="bg-[#F2F5F966]">
              <td className="px-5 py-5 w-[3%]">
                <Skeleton
                  width={18}
                  height={18}
                  borderRadius={2}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </td>
              <td className="px-5 xs:px-8 py-5 group items-center w-[22%]">
                <Skeleton
                  height={18}
                  width={150}
                  borderRadius={2}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </td>
              {Array.from(Array(4).keys()).map((tdElement) => (
                <th key={tdElement} className="px-5 xs:px-8 py-5 w-[25%]">
                  <Skeleton
                    height={18}
                    width={70}
                    borderRadius={2}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from(Array(10).keys()).map((trElement) => (
              <tr
                key={trElement}
                className="px-5 xs:px-8 py-5 odd:bg-white even:bg-[#F2F5F966]"
              >
                <td className="pl-5 py-5 w-[3%]">
                  <Skeleton
                    width={18}
                    height={18}
                    borderRadius={2}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </td>
                <td className="px-5 xs:px-8 py-5 leading-3 group items-center w-[22%]">
                  <Skeleton
                    width={150}
                    height={18}
                    borderRadius={2}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </td>
                {Array.from(Array(4).keys()).map((tdElement) => (
                  <td
                    key={tdElement}
                    className="px-5 xs:px-8 py-5 leading-3 w-[25%]"
                  >
                    <Skeleton
                      height={18}
                      width={100}
                      borderRadius={2}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
  // #endregion
}
