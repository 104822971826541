import React, { useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash-es';

import { CommunicationError, apiAgent } from '../../utils/apicall';
import InputModal from '../../components/modalbootstrap/InputModal';
import {
  fetchPromptRequest,
  fetchShow,
} from '../../features/uxdialog/uxpromptSlice';
import { fetchMaintenanceShow } from '../../features/uxdialog/uxmaintenanceSlice';
import { store } from '../../app/store';
import { setUserInfo } from '../../features/userInfo/userInfoSlice';
import config from '../../utils/config';
import ProSidebar from '../../components/prosidebar/ProSidebar';
import { svgIcons } from '../../components/svgIcons/svgIcons';

export default function Home() {
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const dispatch = useDispatch();
  const promptShowFlag = useSelector(fetchShow);
  const promptRequest = useSelector(fetchPromptRequest);
  const showMaintenance = useSelector(fetchMaintenanceShow);

  const fetchAccountInfo = async () => {
    try {
      const response = await apiAgent.get({
        path: '/users/me',
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      // store.dispatch(hide());
      return await response.json();
    } catch (error) {
      console.log(error);
      if (error instanceof CommunicationError) {
        return 'CommunicationError';
      }
      return null;
    }
  };
  const getAccountInfo = async () => {
    let sendURL = window.location.pathname + window.location.search;
    const Obj = await fetchAccountInfo();
    if (!Obj) {
      window.location.href = `${config.serverurl}users/redirect_auth0_login_page/?target_page=${sendURL}`;
      return;
    }
    if (Obj == 'CommunicationError') {
      // virtual user to show in nav bar
      dispatch(
        setUserInfo({
          photo: '/../../assets/images/Avatar.png',
          first_name: '',
          last_name: '',
          email: '',
        }),
      );
    } else {
      dispatch(setUserInfo(Obj));
    }
  };
  useEffect(() => {
    getAccountInfo();
    const intervalID = setInterval(fetchAccountInfo, 30000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  const backgroundImageStyle = {
    width: '100%', // Set the width of the div
    height: '900px', // Set the height of the div
    backgroundImage: 'url("/assets/images/Maintenance-Background.png")', // Replace with your image URL
    backgroundPosition: 'left bottom', // Anchors the image at the left-bottom corner
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
    backgroundColor: '#FAFAFA',
    // backgroundSize: 'auto', // Original size
    backgroundSize: 'cover', // Ensures the image covers the div area
  };

  return (
    userInfo && (
      <>
        <div className="flex flex-col bg-[#F5FAFF] min-h-screen">
          <div className="bg-white">
            <ProSidebar />
          </div>
          {!showMaintenance ? (
            <div className="w-[1280px] mx-auto xs:grow text-[#161D37]">
              <Outlet />
              <div className="flex justify-end">
                <div className="flex w-[137px] h-8 rounded-sm border-1 pl-1 gap-2 shadow-md items-center mt-6 mb-20 self-end">
                  <h5 className="w-6">{svgIcons.logo}</h5>
                  <div className="text-xs leading-4 font-medium">
                    Powered by Flair
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="w-screen grow min-w-[1280px]"
              style={backgroundImageStyle}
              id="maintenance"
            ></div>
          )}
        </div>
        {showMaintenance ? (
          <div className="fixed translate-x-[-50%] translate-y-[-50%] top-1/2 left-1/2 w-[480px] bg-white border-[#E5E7EB] border rounded-[16px] flex flex-col p-[40px]">
            <p className="text-2xl font-semibold leading-8 text-[#242424]">
              We'll be back soon!
            </p>
            <div className="text-lg leadding-7 font-normal text-[#52525B]">
              <br />
              <p>
                Our team is doing some routine maintenance to make sure Flair is
                the best it can be.
              </p>
              <br />
              <p>Thank you for your patience.</p>
            </div>
            <hr className="my-[24px]" />
            <a
              href="https://www.getflair.io"
              className="h-[52px] bg-[#11181C] rounded-[8px] text-white text-center align-middle py-[14px] text-base leading-6 font-medium"
            >
              Take me Home
            </a>
          </div>
        ) : (
          <></>
        )}
        <InputModal
          show={promptShowFlag && !showMaintenance}
          mark={{
            icon: svgIcons.adjust,
            parentClass: 'text-[#687BFE] bg-[#687BFE]/10',
            childClass: 'w-7 h-7',
          }}
          // headerimage={get(promptRequest, 'headerImagePath', null)}
          title={get(promptRequest, 'title', null)}
          txt={get(promptRequest, 'message', null)}
          inputPlaceholder="Enter Your New Monthly Budget"
          inputType="number"
          inputBackImg="/assets/images/modal-dollar.svg"
          // primaryButtonLabel={get(promptRequest, 'primaryButtonLabel', 'Confirm')}
          // secondaryButtonLabel={get(promptRequest, 'secondaryButtonLabel', 'Cancel')}
          handleConfirm={(value) => {
            get(promptRequest, 'handlePrimary', () => {})(value);
          }}
          // handleSecondary={(value) => {get(promptRequest, 'handleSecondary', () => {})(value)}}
          onClose={(value) => {
            get(promptRequest, 'handleClose', () => {})(value);
          }}
          // onHide={(value) => {get(promptRequest, 'handleClose', () => {})(value)}}
        />
      </>
    )
  );
}
